<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-button type="primary" @click="(inviteDialog = true), getInviteCodes()"
        >Get Invite Url</el-button
      >
      <el-button type="primary" @click="dialogVisible = true"
        >Add Direct Agent</el-button
      >
      <el-button type="primary" @click="addDircet()"
        >Add Direct Member</el-button
      >
      <el-input
        placeholder="Please input search content"
        style="width: 260px; margin-left: 10px"
        v-model="key"
      >
        <template #suffix>
          <el-button type="text" style="margin-right: 10px" @click="getagents()"
            >Search</el-button
          >
        </template>
      </el-input>
      <!-- <el-select v-model="value" placeholder="Select" style="margin-left: 10px">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
    </div>
    <!-- 数据展示表格 -->
    <el-table
      :data="tableData"
      v-loading="loading"
      empty-text="No more Data"
      stripe
      border
      style="width: 100%"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="is_online" label="Presence">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_online === '1'" type="success"
            >Online</el-tag
          >
          <el-tag v-if="scope.row.is_online === '0'" type="info"
            >Offline</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="top_account" label="Parent account">
      </el-table-column>
      <el-table-column prop="user_type" label="customer type">
      </el-table-column>
      <el-table-column prop="nickname" label="Agent name"> </el-table-column>
      <el-table-column prop="username" label="User name"> </el-table-column>
      <!-- <el-table-column prop="account" label="账号"> </el-table-column> -->
      <el-table-column prop="proportion" label="proportion">
        <template slot-scope="scope">
          <span
            @click="
              openChangeProportionDialog(scope.row.id, scope.row.proportion)
            "
            >{{ scope.row.proportion }}%</span
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="agent" label="代理"> </el-table-column>
      <el-table-column prop="member" label="会员"> </el-table-column>
      <el-table-column prop="new" label="新增"> </el-table-column> -->
      <el-table-column prop="money" label="score"> </el-table-column>
      <el-table-column prop="addamount" label="Total Add Score">
      </el-table-column>
      <el-table-column prop="downamount" label="Total Down Score">
      </el-table-column>
      <el-table-column prop="totalrecharge" label="totalRecharge">
      </el-table-column>
      <el-table-column prop="totaldraw" label="totalDraw"> </el-table-column>
      <el-table-column prop="totalbet" label="totalBet"> </el-table-column>
      <el-table-column prop="totalwin" label="totalWin"> </el-table-column>
      <el-table-column prop="discount" label="Deserve Point"> </el-table-column>
      <el-table-column prop="paypoint" label="Points payable">
      </el-table-column>
      <el-table-column label="Estimated Settlement">
        <template slot-scope="scope">
          <span>{{ scope.row.paypoint - scope.row.backpoint }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="backpoint" label="Reflux Points">
      </el-table-column>
      <el-table-column prop="payamount" label="Payable Amount">
      </el-table-column>
      <el-table-column prop="mode" label="Limit mode">
        <template slot-scope="scope">
          <span v-if="scope.row.mode === '1'">Credit model</span>
          <span v-if="scope.row.mode === '2'">Cash model</span>
        </template>
      </el-table-column>
      <el-table-column prop="newDate" label="CreateDate" width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.newDate">{{
            formatDate(scope.row.newDate)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Stats">
        <template slot-scope="scope">
          <span v-if="scope.row.status === '1'">Normal</span>
          <span v-if="scope.row.status === '2'">Blocked</span>
          <span v-if="scope.row.status === '0'">Stop using</span>
        </template>
      </el-table-column>
      <el-table-column width="590" :fixed="fixed">
        <template slot="header">
          <span @click="changeRightSideBar()">function</span>
        </template>
        <template slot-scope="scope">
          <el-popconfirm
            title="Did you really wanna change current user status?"
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="setUserStatuss(scope.row.id, 1)"
          >
            <el-button type="primary" slot="reference" style="margin-left: 10px"
              >Enable</el-button
            >
          </el-popconfirm>
          <el-popconfirm
            title="Did you really wanna change current user status?"
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="setUserStatuss(scope.row.id, 2)"
          >
            <el-button type="warning" slot="reference" style="margin-left: 10px"
              >Ban</el-button
            >
          </el-popconfirm>
          <el-popconfirm
            title="Did you really wanna change current user status?"
            confirm-button-text="Yes"
            cancel-button-text="No"
            @confirm="setUserStatuss(scope.row.id, 0)"
          >
            <el-button type="danger" slot="reference" style="margin-left: 10px"
              >Deactivate</el-button
            >
          </el-popconfirm>
          <el-button
            type="success"
            v-if="scope.row.user_type === 'agent'"
            style="margin-left: 10px"
            @click="openAddScore(scope.row)"
            >Add Score</el-button
          >
          <el-button
            type="warning"
            v-if="scope.row.user_type === 'agent'"
            @click="openDownScore(scope.row)"
            >Down Score</el-button
          >
          <el-button
            type="success"
            v-if="scope.row.user_type === 'member'"
            style="margin-left: 10px"
            @click="addUserCoin(scope.row)"
            >Add Coin</el-button
          >
          <el-button
            type="warning"
            v-if="scope.row.user_type === 'member'"
            @click="downUserCoin(scope.row)"
            >Down Coin</el-button
          >
          <el-button
            type="danger"
            @click="openDeleteDialog(scope.row)"
            style="margin-top: 10px"
            >Delete User</el-button
          >
          <el-button
            type="success"
            v-if="scope.row.user_type === 'agent'"
            @click="agentSettles(scope.row.id)"
            >Settle Accounts</el-button
          >
          <el-button
            type="primary"
            v-if="scope.row.user_type === 'agent'"
            @click="
              (agentDataDialog = true), getAgentForUids(scope.row.username)
            "
            >View Sub-agents</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增代理弹窗 -->
    <el-dialog
      title="Add Second Level Agent"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-row :gutter="20">
        <el-col :xs="24" :lg="12" :offset="0">
          <el-form>
            <el-form-item label="Current account：">
              <span>{{ user }}</span>
            </el-form-item>
            <el-form-item label="Secondary proxy account：">
              <el-input v-model="addUserName"></el-input>
            </el-form-item>
            <el-form-item label="Proxy login password：">
              <el-input v-model="addPassWord"></el-input>
            </el-form-item>
            <el-form-item label="Second level agent name：">
              <el-input v-model="addNickName"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :lg="12" :offset="0">
          <el-form>
            <el-form-item label="Limit mode">
              <el-radio-group v-model="mode">
                <el-radio :label="1">Credit model</el-radio>
                <el-radio :label="2">Cash model</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="current balance">
              <span v-if="money != 0 && money != null">{{ money }}</span>
              <span v-if="money === 0 || money === null">null</span>
            </el-form-item>
            <el-form-item label="Proportion of division">
              <el-input
                v-model="proportion"
                type="number"
                :max="ProportionScore"
                @input="handleInput"
              ></el-input>
              <span>The unit is percentage</span>
              <!-- <span>{{ ProportionScore }}</span> -->
            </el-form-item>
            <span style="color: red" v-if="proportion >= ProportionScore"
              >The agency share shall not be greater than
              {{ ProportionScore }}%</span
            >
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addNewAg()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 新增会员弹窗 -->
    <el-dialog
      title="Add NewMembers"
      :visible.sync="AgentDialogVisible"
      width="70%"
    >
      <el-row :gutter="20">
        <el-col :xs="24" :lg="12" :offset="0">
          <el-form>
            <el-form-item label="Current account:">
              <span>{{ user }}</span>
            </el-form-item>
            <el-form-item label="Member account:">
              <el-input
                v-model="addMember.addusername"
                type="number"
                placeholder="Member account only can input number"
              ></el-input>
              <!-- <p style="margin: 0">Member account only can input number</p> -->
            </el-form-item>
            <el-form-item label="Member Password:">
              <el-input v-model="addMember.addPassWord"></el-input>
            </el-form-item>
            <el-form-item label="Member Name:">
              <el-input v-model="addMember.addNickName"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :lg="12" :offset="0">
          <el-form>
            <!-- <el-form-item label="额度模式">
              <el-radio-group v-model="addMember.mode">
                <el-radio :label="1">信用模式</el-radio>
                <el-radio :label="2">现金模式</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="Current account balance:">
              <span v-if="money != 0 && money != null">{{ money }}</span>
              <span v-if="money === 0 || money === null">null</span>
            </el-form-item>
            <!-- <el-form-item label="分成占比">
              <el-input v-model="addMember.proportion"></el-input>
            </el-form-item> -->
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AgentDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="addNewMe()"
          v-loading.fullscreen.lock="fullscreenLoading"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 上分弹窗 -->
    <el-dialog
      title="Add Agent Score"
      :visible.sync="addScoreDialogvisible"
      :width="dialogWidth"
    >
      <el-divider>Agent Information</el-divider>
      <p>Agent Name：{{ agentUserName }}</p>
      <p>Agent Current Score：{{ agentScore }}</p>
      <el-input v-model="score" placeholder="Please Input Score"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addScoreDialogvisible = false">Cancel</el-button>
        <el-button type="primary" @click="uploadScores()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 下分弹窗 -->
    <el-dialog
      title="Down Agent Score"
      :visible.sync="downScoreDialogvisible"
      :width="dialogWidth"
    >
      <el-divider>Agent Information</el-divider>
      <p>Agent Name：{{ agentUserName }}</p>
      <p>Agent Current Score：{{ agentScore }}</p>
      <el-input v-model="score" placeholder="Please Input Score"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downScoreDialogvisible = false">Cancel</el-button>
        <el-button type="primary" @click="uploadScores()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 用户增加金币弹窗 -->
    <el-dialog
      title="Add Coin Score"
      :visible.sync="upCoinDialog"
      :width="dialogWidth"
    >
      <el-divider>User Information</el-divider>
      <p>User Name：{{ currentUser.username }}</p>
      <el-input v-model="coin" placeholder="Please Input Coin"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upCoinDialog = false">Cancel</el-button>
        <el-button type="primary" @click="addCoins()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 用户减少金币弹窗 -->
    <el-dialog
      title="Down Coin Score"
      :visible.sync="downCoinDialog"
      :width="dialogWidth"
    >
      <el-divider>User Information</el-divider>
      <p>User Name：{{ currentUser.username }}</p>
      <el-input v-model="coin" placeholder="Please Input Coin"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downCoinDialog = false">Cancel</el-button>
        <el-button type="primary" @click="downCoins()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 用户数据删除弹窗 -->
    <el-dialog
      title="User(Agent) Delete From Database"
      :visible.sync="deleteDialog"
      :width="dialogWidth"
    >
      <el-divider>Delete User(Agent) Information</el-divider>
      <p>User Name：{{ delUser.username }}</p>
      <p>Delete User(Agent) is a Warning Operate</p>
      <p>That's mean this data will be delete from database</p>
      <p>You sure you will do this?</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialog = false">Cancel</el-button>
        <el-button type="primary" @click="delUsers()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 结算弹窗 -->
    <el-dialog
      title="Settle Money"
      :visible.sync="settleDialog"
      :width="dialogWidth"
    >
      <p>Current Agent ID:{{ agentId }}</p>
      <p v-loading="settleLoading">Settle Money:{{ settleMoney }}</p>
      <div v-if="settleMoney > 0">
        <p>Payable Amount Has Been Send To Agent</p>
        <p>Agent Can Open Left Side Menu Cilck "USDT Pay" to Pay</p>
        <p>If Agent Is Already Pay To You Then Click "Clear History"</p>
        <p style="color: red">
          After Clearing Agent Data You Can In "Report Manage" View Settle Order
        </p>
      </div>
      <div v-if="settleMoney < 0">
        <p>The Amount is Negative Number That Mean You Have to Pay to Agent</p>
        <p>if You Already Pay to Agent Then Click "Pay And Clear History"</p>
        <p style="color: red">
          After Clearing Agent Data You Can In "Report Manage" View Settle Order
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="settleMoney > 0" type="warning" @click="clearAgents()"
          >Clear History</el-button
        >
        <el-button type="primary" @click="settleDialog = false">OK</el-button>
        <el-button
          v-loading="settleLoading"
          v-if="settleMoney < 0"
          type="success"
          @click="settleAgents()"
          >Pay And Clear History</el-button
        >
      </span>
    </el-dialog>
    <!-- 修改百分比弹窗 -->
    <el-dialog
      title="Change Proportion"
      :visible.sync="proportionDialog"
      :width="dialogWidth"
    >
      <el-input
        v-model="cproportion"
        type="number"
        :max="ProportionScore"
        @input="handleInput1"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="proportionDialog = false">Cancel</el-button>
        <el-button type="primary" @click="changeProportions()"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
    <!-- 邀请链接弹窗 -->
    <el-dialog
      title="Invite Url"
      :visible.sync="inviteDialog"
      :width="dialogWidth"
    >
      <p v-loading="inviteLoading">{{ inviteUrl }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyText()">Copy</el-button>
        <el-button @click="inviteDialog = false">Cancel</el-button>
      </span>
    </el-dialog>
    <!-- 查看代理数据弹窗 -->
    <el-dialog
      title="check Agent Data"
      :visible.sync="agentDataDialog"
      width="80%"
    >
      <!-- 数据展示表格 -->
      <el-table
        :data="agentData"
        v-loading="agentLoading"
        empty-text="No more Data"
        stripe
        border
        style="width: 100%"
      >
        <el-table-column type="selection"> </el-table-column>
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="is_online" label="Presence">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.is_online === '1'" type="success"
              >Online</el-tag
            >
            <el-tag v-if="scope.row.is_online === '0'" type="info"
              >Offline</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="top_account" label="Parent account">
        </el-table-column>
        <el-table-column prop="user_type" label="customer type">
        </el-table-column>
        <el-table-column prop="nickname" label="Agent name"> </el-table-column>
        <el-table-column prop="username" label="User name"> </el-table-column>
        <!-- <el-table-column prop="account" label="账号"> </el-table-column> -->
        <el-table-column prop="proportion" label="proportion">
          <template slot-scope="scope">
            <span
              @click="
                openChangeProportionDialog(scope.row.id, scope.row.proportion)
              "
              >{{ scope.row.proportion }}%</span
            >
          </template>
        </el-table-column>
        <!-- <el-table-column prop="agent" label="代理"> </el-table-column>
      <el-table-column prop="member" label="会员"> </el-table-column>
      <el-table-column prop="new" label="新增"> </el-table-column> -->
        <el-table-column prop="money" label="score"> </el-table-column>
        <el-table-column prop="addamount" label="Total Add Score">
        </el-table-column>
        <el-table-column prop="downamount" label="Total Down Score">
        </el-table-column>
        <el-table-column prop="totalrecharge" label="totalRecharge">
        </el-table-column>
        <el-table-column prop="totaldraw" label="totalDraw"> </el-table-column>
        <el-table-column prop="totalbet" label="totalBet"> </el-table-column>
        <el-table-column prop="totalwin" label="totalWin"> </el-table-column>
        <el-table-column prop="discount" label="Deserve Point">
        </el-table-column>
        <el-table-column prop="paypoint" label="Points payable">
        </el-table-column>
        <el-table-column label="Estimated Settlement">
          <template slot-scope="scope">
            <span>{{ scope.row.paypoint - scope.row.backpoint }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="backpoint" label="Reflux Points">
        </el-table-column>
        <el-table-column prop="payamount" label="Payable Amount">
        </el-table-column>
        <el-table-column prop="mode" label="Limit mode">
          <template slot-scope="scope">
            <span v-if="scope.row.mode === '1'">Credit model</span>
            <span v-if="scope.row.mode === '2'">Cash model</span>
          </template>
        </el-table-column>
        <el-table-column prop="newDate" label="CreateDate" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.newDate">{{
              formatDate(scope.row.newDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Stats">
          <template slot-scope="scope">
            <span v-if="scope.row.status === '1'">Normal</span>
            <span v-if="scope.row.status === '2'">Blocked</span>
            <span v-if="scope.row.status === '0'">Stop using</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="agentDataDialog = false">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getagent,
  addNewAgent,
  setUserStatus,
  uploadScore,
  addNewMember,
  loadScore,
  changeUserChannel,
  addCoin,
  downCoin,
  deleteUser,
  agentSettle,
  USDTPay,
  changeProportion,
  settleAgent,
  getInviteCode,
  getAgentForUid,
  clearAgent,
} from "@/api/api";
import moment from "moment";
import * as msgpack from "@msgpack/msgpack";
import CryptoJS from "crypto-js";
// import { Loading } from "element-ui";

export default {
  data() {
    return {
      type: "",
      options: [
        { value: "选项1", label: "直属代理" },
        { value: "选项2", label: "直属会员" },
        { value: "选项3", label: "全部代理" },
        { value: "选项4", label: "全部会员" },
      ],
      value: "",
      tableData: [],
      loading: false,
      dialogVisible: false,
      AgentDialogVisible: false,
      downScoreDialogvisible: false,
      user: "",
      mode: 1,
      num: "1%",
      money: 0,
      checkList: [],
      makeOptions: [
        { value: "选项1", label: "水全退到底" },
        { value: "选项2", label: "赚取0.1%退水" },
        { value: "选项3", label: "赚取0.3%退水" },
        { value: "选项4", label: "赚取0.5%退水" },
        { value: "选项5", label: "赚取1.0%退水" },
        { value: "选项6", label: "赚取所有退水" },
      ],
      make: "",
      addUserName: "",
      addPassWord: "",
      addNickName: "",
      proportion: 0,
      addMember: [],
      addScoreDialogvisible: false,
      agentScore: "",
      agentUserName: "",
      score: "",
      scoreType: "1",
      address: "ws9951.7771101.com",
      handlers: {}, // 去掉了下划线
      curtime: 0, // 去掉了下划线
      ws: null, // 去掉了下划线
      idx: 0, // 去掉了下划线
      token: null,
      autoConnectCount: 0, // 去掉了下划线
      subid: null,
      uid: null,
      fullscreenLoading: false,
      currentUser: [],
      upCoinDialog: false,
      coin: "",
      deleteDialog: false,
      delUser: "",
      downCoinDialog: false,
      dialogWidth: "30%",
      ProportionScore: 0,
      settleMoney: "",
      settleDialog: false,
      agentId: "",
      settleLoading: false,
      proportionDialog: false,
      cproportion: "",
      cid: "", // changeid
      code: "",
      inviteUrl: "",
      inviteLoading: false,
      inviteDialog: false,
      fixed: false,
      agentData: [],
      agentLoading: true,
      agentDataDialog: false,
      key: "",
    };
  },
  methods: {
    clearAgents() {
      this.fullscreenLoading = true;
      clearAgent(this.agentId, this.settleMoney).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("success");
          this.getagents();
          this.settleDialog = false;
          this.fullscreenLoading = false;
        } else {
          this.$message.error("error!" + res.data.msg);
          this.fullscreenLoading = false;
        }
      });
    },
    changeRightSideBar() {
      this.$message.success("success");
      if (this.fixed === "none") {
        this.fixed = "fixed";
      } else if (this.fixed === "fixed") {
        this.fixed = "false";
      }
    },
    getAgentForUids(username) {
      this.agentLoading = true;
      getAgentForUid(username).then((res) => {
        if (res.data.code === 200) {
          this.agentData = res.data.data;
          this.agentLoading = false;
        } else {
          this.$message.error("Get Agent Error: " + res.data.msg);
          this.agentLoading = false;
        }
      });
    },
    copyText() {
      const url = this.inviteUrl;
      navigator.clipboard.writeText(url);
      this.$message.success("Copy Success");
    },
    getInviteCodes() {
      this.inviteLoading = true;
      const username = localStorage.getItem("username");
      getInviteCode(username).then((res) => {
        if (res.data.code === 200) {
          this.inviteUrl =
            "https://www.7771101.com/?inviteCode=" +
            res.data.msg +
            "&is_channel";
          this.inviteLoading = false;
        } else {
          this.$message.error("Get Invite Code Error: " + res.data.msg);
          this.inviteLoading = false;
        }
      });
    },
    settleAgents() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const username = localStorage.getItem("username");
      settleAgent(username, this.agentId, this.settleMoney).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("settle Success!Agent Data is Clear!");
          this.getagents();
          this.settleDialog = false;
          loading.close();
        } else {
          this.$message.error("settle Fail!" + res.data.msg);
          loading.close();
        }
      });
    },
    handleInput(value) {
      // 如果输入的值大于最大值，则将输入值设置为最大值
      if (Number(value) > this.ProportionScore) {
        this.proportion = this.ProportionScore.toString();
      }
    },
    handleInput1(value) {
      // 如果输入的值大于最大值，则将输入值设置为最大值
      if (Number(value) > this.ProportionScore) {
        this.cproportion = this.ProportionScore.toString();
      }
    },
    changeProportions() {
      changeProportion(this.cid, this.cproportion).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("Change Proportion Success!");
          this.getagents();
          this.proportionDialog = false;
        } else {
          this.$message.error("Change Proportion Failed!");
        }
      });
    },
    openChangeProportionDialog(cid, e) {
      this.cid = cid;
      this.cproportion = e;
      this.proportionDialog = true;
    },
    USDTPays() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      USDTPay(this.settleMoney).then((res) => {
        if (res.data.code === 200) {
          window.open(res.data.msg.url);
          loading.close();
        } else {
          this.$message.error("Get Pay Url Fail！" + res.data.msg);
          loading.close();
        }
      });
    },
    agentSettles(aid) {
      this.settleDialog = true;
      this.settleLoading = true;
      this.agentId = aid;
      const id = localStorage.getItem("id");
      agentSettle(id, aid).then((res) => {
        if (res.data.code === 200) {
          this.settleMoney = res.data.msg;
          this.settleLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.settleLoading = false;
        }
      });
    },
    delUsers() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      deleteUser(this.delUser.username).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("Delete Success!");
          this.deleteDialog = false;
          this.getagents();
          loading.close();
        } else {
          this.$message.error("Delete Failed!");
          this.deleteDialog = false;
          loading.close();
        }
      });
    },
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
      } else {
        this.dialogWidth = "30%";
        this.fixed = "right";
      }
    },
    downCoins() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = "23e47f94f0bb5086fd406368466c54e9";
      const id = localStorage.getItem("id");
      downCoin(token, this.currentUser.username, this.coin, id).then((res) => {
        if (res.data.status === "success") {
          this.$message.success("Down Coin Success!");
          this.downCoinDialog = false;
          // this.getagents();
          this.$emit("contentChanged");
          // this.$router.go(0);
          loading.close();
          // window.location.reload();
        } else {
          this.$message.error("Down Coin Failed!" + res.data.message);
          loading.close();
        }
      });
    },
    openDeleteDialog(user) {
      this.delUser = user;
      this.deleteDialog = true;
    },
    addCoins() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const token = "33455asdasdsad";
      const id = localStorage.getItem("id");
      const username = localStorage.getItem("username");
      addCoin(token, this.currentUser.username, this.coin, id, username).then(
        (res) => {
          if (res.data.code === 200) {
            this.$message.success("Add Coin Success!");
            this.$emit("contentChanged");
            this.upCoinDialog = false;
            loading.close();
            // this.$router.go(0);
          } else {
            this.$message.error("Add Coin Failed!" + res.data.msg);
            loading.close();
          }
        }
      );
    },
    addUserCoin(row) {
      this.currentUser = row;
      this.upCoinDialog = true;
    },
    downUserCoin(row) {
      this.currentUser = row;
      this.downCoinDialog = true;
    },
    addNewMe() {
      this.autoTravellerLogin();
      this.addNewMembers();
      this.fullscreenLoading = true;
    },
    addNewAg() {
      this.addMember.addusername = this.addUserName;
      this.addMember.addPassWord = this.addPassWord;
      this.addMember.addNickName = this.addNickName;
      this.autoTravellerLogin();
      this.addNewAgents();
      this.fullscreenLoading = true;
    },
    openFullScreen1() {
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
      }, 2000);
    },
    connect(serverAddress, callback) {
      let url = `wss://${serverAddress}/ws`;
      this.ws = new WebSocket(url);

      this.ws.onopen = () => {
        console.log("WebSocket 连接已打开");

        this.autoConnectCount = 0;

        if (callback) callback();
      };

      this.ws.onmessage = (event) => {
        console.log("收到消息:", event.data);
        this.handleResponeData(event.data);
      };

      this.ws.onerror = (error) => {
        console.error("WebSocket 通信发生错误:", error);
      };

      this.ws.onclose = () => {
        console.log("WebSocket 连接已关闭");
        if (this.address === "ws9951.7771101.com" && this.uid) {
          this.userinfo();
        }
      };
    },
    reqLogin(nickname, pwd, loginType, accesstoken, loginExData, token) {
      this.connect(this.address, () => {
        const req = this.constructLoginMsg(
          nickname,
          pwd,
          loginType,
          accesstoken,
          loginExData,
          token
        );
        req.bundleid = "com.yono.games.free";
        req.deviceid = "0";
        this.send(req);
      });
    },
    handleResponeData(msgData) {
      const decodeArrayBuff = (arrayBuf) => {
        const dataview = new DataView(arrayBuf);
        const headSize = 8;
        const uint8Array = new Uint8Array(arrayBuf.byteLength - headSize);

        for (let i = 0; i < uint8Array.length; i++) {
          uint8Array[i] = dataview.getUint8(headSize + i);
        }

        const msgDic = msgpack.decode(uint8Array);
        const parsedMsg = JSON.parse(msgDic);
        console.log(parsedMsg);
        //处理成功逻辑
        if (parsedMsg.c === 134 && parsedMsg.code === 200) {
          // this.$message.success("Add Member Success!");
          changeUserChannel(this.addMember.addNickName).then((res) => {
            if (res.data.code === 200) {
              this.$message.success("Add Game Member Success!");
              this.fullscreenLoading = false;
            } else {
              this.$message.error("Add Member Fail!");
              this.fullscreenLoading = false;
            }
          });
          // loading.close();
        }

        if (parsedMsg.c === 1) {
          this.uid = parsedMsg.uid;
          this.subid = parsedMsg.subid;
          this.token = parsedMsg.token;
        }
      };

      if (window.FileReader && msgData instanceof Blob) {
        const reader = new FileReader();
        reader.addEventListener("loadend", () => {
          decodeArrayBuff(reader.result);
        });
        reader.readAsArrayBuffer(msgData);
      } else {
        decodeArrayBuff(msgData);
      }
    },
    constructLoginMsg(
      nickname,
      pwd,
      loginType,
      accesstoken,
      loginExData,
      token
    ) {
      return {
        c: 1,
        user: nickname,
        passwd: pwd,
        app: 17,
        v: "1.0.0.0",
        t: 1,
        accessToken: accesstoken,
        platform: "H5_Windows",
        deviceToken: "0",
        phone: "Web_Windows",
        token: token,
        bwss: 0,
        LoginExData: 1,
        language: 2,
        client_uuid: new Date().getTime() + Math.random(1, 9999999),
      };
    },
    isConnect() {
      const state = this.ws && this.ws.readyState === WebSocket.OPEN;
      console.log("连接状态:", this.ws.readyState);
      console.log("连接状态:", state);
      return state;
    },
    autoTravellerLogin() {
      let localNickname = "Guest" + Math.floor(Math.random() * 9001) + 999;
      const token =
        new Date().getTime() + "_" + Math.floor(Math.random() * 99999999) + 1;
      this.reqLogin(localNickname, localNickname, 1, "", 1, token);
    },
    send(msgDic) {
      if (this.isConnect()) {
        if (typeof msgDic === "string") {
          msgDic = JSON.parse(msgDic);
        }
        msgDic.c_ts = new Date().getTime();
        if (msgDic.c === 1) {
          msgDic.x = CryptoJS.MD5(
            msgDic.c_ts.toString() + "hero888"
          ).toString();
        }
        msgDic.c_idx = this.idx++;
        msgDic.language = 2;
        console.log(msgDic);

        const bodyData = this.pack(JSON.stringify(msgDic));
        const headData = this.generateHead(bodyData);
        const uint8Array = this.linkHeadBody(headData, bodyData);
        this.ws.send(uint8Array);
        return true;
      } else {
        console.log("WebSocket 未连接！");
      }
      return false;
    },
    pack(msgDic) {
      return msgpack.encode(msgDic);
    },
    generateHead(bodyData) {
      const msgLen = bodyData.length;
      const len = this.jsToCByShort(msgLen);
      const time = this.jsToCByInt(Math.floor(this.curtime / 1000));
      const checkSum = this.getCheckSum(bodyData, msgLen);

      return `${len}${checkSum}${time}`;
    },
    jsToCByShort(value) {
      const low1 = Math.floor(value / 256);
      const low2 = Math.floor(value % 256);
      return String.fromCharCode(low1, low2);
    },
    jsToCByInt(value) {
      const low1 = Math.floor(value / (256 * 256 * 256));
      const low2 = Math.floor(value / (256 * 256)) % 256;
      const low3 = Math.floor(value / 256) % 256;
      const low4 = Math.floor(value % 256);
      return String.fromCharCode(low1, low2, low3, low4);
    },
    getCheckSum(bodyData, msgLen) {
      let src =
        msgLen < 128
          ? this.srcSum(bodyData, msgLen)
          : this.srcSum(bodyData, 128);
      return this.jsToCByShort(src);
    },
    srcSum(strData, len) {
      let sum = 65535;
      for (let i = 0; i < len; i++) {
        const d = strData[i];
        sum = sum ^ d;
        sum = (sum >> 1) ^ (sum & 1 ? 0x70b1 : 0);
      }
      return sum;
    },
    linkHeadBody(headData, bodyDataBuf) {
      const headLen = headData.length;
      const bodyLen = bodyDataBuf.length;
      const uint8Array = new Uint8Array(headLen + bodyLen);
      for (let i = 0; i < headLen; i++) {
        uint8Array[i] = headData.charCodeAt(i);
      }
      for (let i = 0; i < bodyLen; i++) {
        uint8Array[headLen + i] = bodyDataBuf[i];
      }
      return uint8Array;
    },
    userinfo() {
      this.connect("ws9959.7771101.com", () => {
        const req = {
          c: 2,
          uid: this.uid,
          openid: "",
          server: "node",
          subid: this.subid,
          token: this.token,
          deviceid: "0",
          appver: "1.0.0",
        };
        this.send(req);
        setTimeout(() => {
          this.send({
            c: 134,
            phone: this.addMember.addusername,
            code: "111111",
            pwd: this.addMember.addPassWord,
            name: this.addMember.addNickName,
            uid: this.uid,
          });
        }, 1000);
      });
    },
    bind() {
      this.address = "ws9959.7771101.com";
      this.connect(this.address);
    },
    addDircet() {
      this.AgentDialogVisible = true;
      // this.autoTravellerLogin();
    },
    createUser() {
      this.autoTravellerLogin();
      this.fullscreenLoading = true;
      this.addMember();
    },
    openAddScore(row) {
      this.scoreType = "1";
      this.addScoreDialogvisible = true;
      this.agentScore = row.money;
      this.agentUserName = row.username;
    },

    openDownScore(row) {
      this.scoreType = "2";
      this.downScoreDialogvisible = true;
      this.agentScore = row.money;
      this.agentUserName = row.username;
    },

    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },

    getagents() {
      this.loading = true;
      const username = localStorage.getItem("username");
      getagent(username, this.key).then((res) => {
        if (res.data.msg === 200) {
          this.tableData = res.data.data;
          this.loading = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
          this.loading = false;
        }
      });
    },

    addNewAgents() {
      if (
        (this.user,
        this.addUserName,
        this.addPassWord,
        this.addNickName,
        this.mode,
        this.proportion)
      ) {
        addNewAgent(
          this.user,
          this.addUserName,
          this.addPassWord,
          this.addNickName,
          this.mode,
          this.proportion
        ).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "Add New Agent Success!",
              type: "success",
            });
            this.dialogVisible = false;
            this.getagents();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      } else {
        this.$message.error("Please Check The Form");
      }
    },

    setUserStatuss(id, status) {
      setUserStatus(id, status).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("操作成功！");
          this.getagents();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    uploadScores() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255,255, 0.7)",
      });
      const agent = localStorage.getItem("username");
      const id = localStorage.getItem("id");
      uploadScore(this.agentUserName, agent, this.score, this.scoreType, id)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("Score Success！");
            this.addScoreDialogvisible = false;
            this.downScoreDialogvisible = false;
            this.getagents();
            // this.loadScores();
            this.$emit("contentChanged");
            loading.close();
          } else {
            this.$message.error(res.data.msg);
            loading.close();
          }
        })
        .catch((res) => {
          loading.close();
          this.$message.error(res.data.msg);
        });
    },

    addNewMembers() {
      const user = localStorage.getItem("username");
      addNewMember(
        user,
        this.addMember.addusername,
        this.addMember.addPassWord,
        this.addMember.addNickName,
        this.addMember.mode,
        this.addMember.proportion
      ).then((res) => {
        if (res.data.code === 200) {
          this.$message.success("Add Member Success！");
          this.AgentDialogVisible = false;
          this.getagents();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    loadScores() {
      const username = localStorage.getItem("username");
      loadScore(username).then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem("money", res.data.msg);
        } else {
          this.$message.error("load money faild" + res.data.msg);
        }
      });
    },
  },

  created() {
    this.getagents();
    this.user = localStorage.getItem("username");
    this.loadScores();
    this.curtime = new Date().getTime(); // 初始化时间戳
    // this.autoTravellerLogin(); // 自动游客登录
    this.screeneeWidth();
    this.ProportionScore = localStorage.getItem("proportion");
    this.getInviteCodes();
  },
};
</script>

<style></style>
