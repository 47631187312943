<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <tiny-button @click="dialogVisible = true" type="primary"
        >Report query settings</tiny-button
      >
    </div>
    <!-- 数据展示表格 -->
    <!-- <el-table
      :data="tableData"
      v-loading="loading"
      border
      stripe
      style="width: 100%"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-form-item label="玩家id">
              <span>{{ props.row.uid }}</span>
            </el-form-item>
            <el-form-item label="所属店铺">
              <span>{{ props.row.shop }}</span>
            </el-form-item>
            <el-form-item label="商品 ID">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="店铺 ID">
              <span>{{ props.row.shopId }}</span>
            </el-form-item>
            <el-form-item label="商品分类">
              <span>{{ props.row.category }}</span>
            </el-form-item>
            <el-form-item label="店铺地址">
              <span>{{ props.row.address }}</span>
            </el-form-item>
            <el-form-item label="商品描述">
              <span>{{ props.row.desc }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="uid" label="玩家id"> </el-table-column>
      <el-table-column prop="playername" label="玩家名称"> </el-table-column>
      <el-table-column prop="reg_ip" label="注册ip"> </el-table-column>
      <el-table-column prop="onlinestate" label="onlinestate">
      </el-table-column>
      <el-table-column prop="coin" label="金额"> </el-table-column>
      <el-table-column prop="dcoin" label="提现金额"> </el-table-column>
      <el-table-column prop="gamedraw" label="gamedraw"> </el-table-column>
      <el-table-column prop="tagid" label="标签等级id"> </el-table-column>
      <el-table-column prop="totalbet" label="总下注"> </el-table-column>
      <el-table-column prop="totalwin" label="总赢分"> </el-table-column>
      <el-table-column prop="cashbonus" label="bonus coin"> </el-table-column>
      <el-table-column prop="gamestate" label="游戏状态"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间"> </el-table-column>
      <el-table-column label="操作"> </el-table-column>
    </el-table> -->
    <tiny-grid
      :data="tableData"
      border
      :edit-config="{ trigger: 'click', mode: 'cell', showStatus: true }"
    >
      <tiny-grid-column field="id" title="ID"></tiny-grid-column>
      <tiny-grid-column field="orderid" title="OrderID"></tiny-grid-column>
      <tiny-grid-column field="amount" title="Amount"></tiny-grid-column>
      <tiny-grid-column
        field="username"
        title="Agent UserName"
      ></tiny-grid-column>
      <tiny-grid-column field="create_time" title="Settlement Time">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.create_time) }}</span>
        </template>
      </tiny-grid-column>
      <tiny-grid-column field="pay_time" title="Pay Time">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_time">{{
            formatDate(scope.row.pay_time)
          }}</span>
        </template>
      </tiny-grid-column>
      <tiny-grid-column field="status" title="Status">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0" style="color: red"
            >Not Pay Yet</span
          >
          <span v-if="scope.row.status == 1" style="color: greenyellow"
            >Is Pay</span
          >
        </template>
      </tiny-grid-column>
      <tiny-grid-column field="type" title="order Type">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">Agent Pay To You</span>
          <span v-if="scope.row.type == 1">You Pay To Agent</span>
        </template>
      </tiny-grid-column>
    </tiny-grid>
    <!-- 分页器 -->
    <!-- <el-row :gutter="20" style="margin: 0 20px 20px 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>Total {{ total }} Page</span>
        <span style="margin-left: 20px">Show Page</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="GetRechargeLists"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <div>
            Jump To
            <el-input
              v-model="cpage"
              @blur="changePage(cpage)"
              style="width: 80px"
            ></el-input>
            Page
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row> -->
    <!-- 报表查询设定dialog -->
    <el-dialog
      title="Report query settings"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form label-width="80px">
        <el-form-item label="Date Range">
          <el-date-picker
            v-model="date"
            align="right"
            type="date"
            placeholder="Select Date"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Settlement status">
          <el-radio-group v-model="balance">
            <el-radio :label="1">Unsettled</el-radio>
            <el-radio :label="2">Already settled</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Username">
          <el-input v-model="user" placeholder="Pls Enter Username"></el-input>
        </el-form-item>
        <el-form-item label="Membership amount">
          <el-input
            v-model="memberMoney"
            placeholder="Please enter the membership amount"
          ></el-input>
        </el-form-item>
        <el-form-item label="Profit and loss amount">
          <el-input
            v-model="lossMoney"
            placeholder="Please enter the profit and loss amount"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPayable } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      date: "",
      dialogVisible: false,
      tableData: [],
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "One week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      type: "",
      balance: "",
      user: "",
      memberMoney: "",
      lossMoney: "",
      page: 1,
      list: 15,
      total: 0, // 总页数
      currentPage: 1,
      cpage: "",
    };
  },
  methods: {
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getplayerLists();
    },
    getPayables() {
      this.loading = true;
      const username = localStorage.getItem("username");
      getPayable(username).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.msg;
          // this.total = res.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getPayables();
  },
};
</script>

<style></style>
