<template>
  <div>
    <div style="margin: 0 0 10px 10px">
      <el-input
        v-model="key"
        style="width: 300px"
        placeholder="Search UserName"
      ></el-input>
      <el-button
        type="primary"
        @click="getScoreLogs()"
        style="margin-left: 10px"
        >Search</el-button
      >
    </div>
    <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
      <el-table-column prop="id" label="ID"> </el-table-column>
      <el-table-column prop="score" label="Operating Fraction">
      </el-table-column>
      <el-table-column prop="user" label="Agent Name"> </el-table-column>
      <el-table-column prop="agent" label="Operator"> </el-table-column>
      <el-table-column prop="type" label="Operation Type">
        <template slot-scope="scope">
          <span v-if="scope.row.type === '1'">Add Score</span>
          <span v-if="scope.row.type === '2'">Down Score</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="Operation Time">
        <template slot-scope="scope">
          <span>{{ formatDate(scope.row.createtime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-row :gutter="20" style="margin: 0 20px 20px 20px">
      <el-col :span="12" :offset="0" style="line-height: 60px">
        <span>Total {{ total }} Page</span>
        <span style="margin-left: 20px">Show Page</span>
        <el-input-number
          v-model="list"
          controls-position="right"
          @change="getScoreLogs"
          :min="1"
          :max="100"
          style="margin-left: 10px"
        ></el-input-number>
      </el-col>
      <el-col :span="12" :offset="0">
        <div
          style="
            display: flex;
            justify-content: right;
            align-items: right;
            margin-top: 20px;
          "
        >
          <div>
            Jump To
            <el-input
              v-model="currentPage"
              @blur="changePage(currentPage)"
              style="width: 80px"
            ></el-input>
            Page
          </div>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage - 1)"
            :disabled="currentPage === 1"
          >
            <i class="el-icon-arrow-left"></i>
          </el-button>
          <el-button
            round
            style="padding: 10px"
            @click="changePage(currentPage + 1)"
            :disabled="currentPage === total"
          >
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getScoreLog } from "@/api/api";
import moment from "moment";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      page: 1,
      list: 10,
      total: 0, // 总页数
      currentPage: 1,
      key: "",
    };
  },
  methods: {
    changePage(page) {
      if (page < 1 || page > this.total) return;
      this.currentPage = page;
      this.page = page;
      this.getScoreLogs();
    },
    getScoreLogs() {
      this.loading = true;
      const username = localStorage.getItem("username");
      getScoreLog(username, this.key, this.page, this.list).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.totalPage;
          this.loading = false;
        } else {
          this.$message.error("Get Log Fail!" + res.data.msg);
          this.loading = false;
        }
      });
    },
    // 时间格式化
    formatDate(date) {
      return moment.unix(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {
    this.getScoreLogs();
  },
};
</script>

<style></style>
