<template>
  <div style="height: 100vh; display: flex">
    <!-- 左侧菜单 -->
    <div
      :style="{
        width: isCollapse ? '0px' : '250px',
        transition: 'all 0.3s ease',
      }"
    >
      <!-- 手机页面左侧菜单按钮 -->
      <i
        class="el-icon-s-unfold phone-menu"
        @click="isCollapse = !isCollapse"
      ></i>
      <el-menu
        default-active="1"
        background-color="#061f3e"
        text-color="#fff"
        active-text-color="#ffd04b"
        class="el-menu-vertical-demo"
        style="height: 100%; width: 100%"
        :collapse="isCollapse"
      >
        <div style="z-index: -1">
          <img src="@/assets/logo.jpg" alt="" style="width: 100%" />
          <h4
            style="text-align: center; color: white; cursor: pointer"
            @click="setContactDialog = true"
          >
            My Contact:<br />
            <span v-if="contact == ''">Not Setting Yet</span>
            <span v-else>{{ contact }}</span>
          </h4>
        </div>
        <div
          style="color: #fff; padding: 10px 25px"
          @click="isCollapse = !isCollapse"
        >
          <i
            v-if="isCollapse === false"
            class="el-icon-arrow-left"
            style="margin-right: 5px"
          ></i>
          <i v-else class="el-icon-arrow-right"></i>
          <span v-if="isCollapse === false">Close Menu</span>
        </div>
        <!-- <el-menu-item index="0" @click="isCollapse = !isCollapse">
          <i v-if="isCollapse === false" class="el-icon-arrow-left"></i>
          <i v-else class="el-icon-arrow-right"></i>
          <span slot="title">Close Menu</span>
        </el-menu-item> -->
        <el-menu-item index="1" @click="open(1)">
          <i class="el-icon-menu"></i>
          <span slot="title">User Manage</span>
        </el-menu-item>
        <el-menu-item index="2" @click="open(2)">
          <i class="el-icon-s-management"></i>
          <span slot="title">Report Manage</span>
        </el-menu-item>
        <el-menu-item index="3" @click="openPayDialog()">
          <i class="el-icon-coin"></i>
          <span slot="title">USDT Pay</span>
        </el-menu-item>
        <el-menu-item index="4" @click="open(4)">
          <i class="el-icon-document"></i>
          <span slot="title">Score Record</span>
        </el-menu-item>
        <el-menu-item index="5" @click="getSuperiorsContacts()">
          <i class="el-icon-phone"></i>
          <span slot="title">Contact Superior</span>
        </el-menu-item>
        <el-button
          style="width: 100%"
          type="danger"
          @click="changePwdDialog = true"
          class="phone_btn"
          >Change Password</el-button
        >
        <el-button
          style="width: 100%"
          type="warning"
          @click="logOuts()"
          class="phone_btn"
          >Login Out</el-button
        >
      </el-menu>
    </div>
    <!-- 右侧内容 -->
    <div style="height: 100vh; flex: 1; width: 100vh; overflow: auto">
      <div
        style="
          display: flex;
          justify-content: flex-start;
          height: 40px;
          padding-left: 10px;
        "
      >
        <el-popover placement="top-start" trigger="hover">
          <p>User：{{ user }}</p>
          <p>Score：{{ money }}</p>
          <p>Share：{{ share }}%</p>
          <el-button
            style="margin: 10px 10px 0 0; width: 100%"
            @click="changePwdDialog = true"
            type="warning"
            >Change Password</el-button
          >
          <el-button
            style="margin: 10px 10px 0 0; width: 100%"
            @click="logOuts()"
            type="danger"
            >LoginOut</el-button
          >
          <div slot="reference" class="pc-data">
            Welcome! {{ user }} Score：<span style="color: red">{{
              money
            }}</span>
            Current share：<span style="color: red">{{ share }}%</span> Points
            payable：<span style="color: red">{{ payPoint }}</span> Reflux
            Points：<span style="color: red">{{ backpoint }}</span> Deserve
            Point：<span style="color: red">{{ discount }}</span>
          </div>
          <!-- 手机端显示数据 -->
          <div slot="reference" class="phone-card">
            <el-row :gutter="20">
              <el-col :span="12" :offset="0">
                {{ user }}
                <p style="margin: 0; font-size: 15px; color: #cccccc">
                  Current User
                </p>
              </el-col>
              <el-col :span="12" :offset="0">
                {{ money }}
                <p style="margin: 0; font-size: 15px; color: #cccccc">Score</p>
              </el-col>
              <el-col :span="12" :offset="0">
                {{ share }}%
                <p style="margin: 0; font-size: 15px; color: #cccccc">
                  Current share
                </p>
              </el-col>
              <el-col :span="12" :offset="0">
                {{ payPoint }}
                <p style="margin: 0; font-size: 15px; color: #cccccc">
                  Points payable
                </p>
              </el-col>
              <el-col :span="12" :offset="0">
                {{ backpoint }}
                <p style="margin: 0; font-size: 15px; color: #cccccc">
                  Reflux Points
                </p>
              </el-col>
              <el-col :span="12" :offset="0">
                {{ discount }}
                <p style="margin: 0; font-size: 15px; color: #cccccc">
                  Deserve Point
                </p>
              </el-col>
            </el-row>
          </div>
        </el-popover>
      </div>
      <!-- 标签页内容 -->
      <el-tabs
        v-if="isShow == true"
        v-model="editableTabsValue"
        type="card"
        editable
        @edit="handleTabsEdit"
        class="tabs"
        style="position: relative"
      >
        <el-tab-pane
          :key="item.name"
          v-for="item in editableTabs"
          :label="item.title"
          :name="item.name"
        >
          <component
            :is="item.component"
            @contentChanged="refreshContent"
          ></component>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 结算弹窗 -->
    <el-dialog title="Pay Dialog" :visible.sync="payDialog" width="30%">
      <p>Payable：{{ payamount }} INR</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payDialog = false">Cancel</el-button>
        <el-select
          v-if="payChannel"
          v-model="payc"
          placeholder="Choose Pay Channel"
        >
          <el-option
            v-for="item in payChannel"
            :key="item.id"
            :label="item.channel"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          v-if="payamount > 0 && payChannel"
          type="primary"
          @click="pays()"
          >Pay</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置联系方式弹窗 -->
    <el-dialog title="Set Contact" :visible.sync="setContactDialog" width="30%">
      <el-input v-model="contact" placeholder="Please input contact"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setContactDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateContacts()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 展示上级联系方式弹窗 -->
    <el-dialog
      title="Superior Contact"
      :visible.sync="showContactDialog"
      width="30%"
    >
      <p>contact：{{ superiorsContact }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyText(superiorsContact)"
          >Copy Contact</el-button
        >
        <el-button @click="showContactDialog = false">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码弹窗 -->
    <el-dialog
      title="Change Password"
      :visible.sync="changePwdDialog"
      width="30%"
    >
      <el-input
        placeholder="Please Input Your New Password"
        v-model="password"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePwdDialog = false">Cancel</el-button>
        <el-button type="primary" @click="changePwds()">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UserVue from "./UserVue.vue";
import QueryVue from "./QueryVue.vue";
import CreateVue from "./CreateVue.vue";
import RecordVue from "./RecordVue.vue";
import {
  changePwd,
  getContact,
  getPayAmount,
  getPayChannel,
  getSuperiorsContact,
  getUserInfo,
  pay,
  updateContact,
  USDTPay,
} from "@/api/api";
export default {
  data() {
    return {
      username: "",
      avatar: "",
      oldPassword: "",
      newPassword: "",
      editableTabs: [], // 初始化为空数组
      editableTabsValue: "1",
      isShow: true,
      dialogVisible: false,
      componentsList: {
        1: "UserVue",
        2: "QueryVue",
        3: "CreateVue",
        4: "RecordVue",
      },
      currentView: "UserVue",
      user: "",
      money: "",
      isCollapse: false,
      share: "",
      payPoint: "",
      backpoint: "",
      discount: "",
      payamount: "",
      payDialog: false,
      contact: "",
      setContactDialog: false,
      superiorsContact: "",
      showContactDialog: false,
      payChannel: [],
      payc: "",
      changePwdDialog: false,
      password: "",
    };
  },
  methods: {
    // 修改密码
    changePwds() {
      if (this.password) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const id = localStorage.getItem("id");
        changePwd(id, this.password).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("Change Password Success! Login Out!");
            loading.close();
            this.logOuts();
          } else {
            this.$message.error("Change Password Fail!" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("Please Check The Password!");
      }
    },
    // 代理支付
    pays() {
      if (this.payc) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const id = localStorage.getItem("id");
        pay(this.payc, id, this.payamount).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("Opening Pay Page");
            window.open(res.data.msg.data.payurl, "_blank");
            loading.close();
          } else {
            loading.close();
            console.log(res);
            this.$message.error("Pay Fail!" + res.data.data);
          }
        });
      } else {
        this.$message.error("Please Choose Pay Channel!");
      }
    },
    getPayChannels() {
      getPayChannel().then((res) => {
        console.log("Raw Response:", res);

        let parsedData;
        try {
          // 解析 res.data
          parsedData =
            typeof res.data === "string" ? JSON.parse(res.data) : res.data;
          console.log("Parsed Data:", parsedData);
        } catch (error) {
          console.error("Failed to parse response:", error);
          this.$message.error("Invalid response format.");
          return;
        }

        // 检查解析后的数据是否是目标格式
        if (parsedData && Array.isArray(parsedData.data)) {
          this.payChannel = parsedData.data.map((item) => {
            const [channel, id] = item.split("_");
            return {
              id: Number(id),
              channel,
            };
          });
          console.log("Processed Pay Channel:", this.payChannel);
        } else {
          this.$message.error("Get Pay Channel Fail! Data format is invalid.");
        }
      });
    },
    copyText(e) {
      navigator.clipboard.writeText(e);
      this.$message.success("Copy Success!");
    },
    getSuperiorsContacts() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const id = localStorage.getItem("id");
      getSuperiorsContact(id).then((res) => {
        if (res.data.code === 200) {
          this.superiorsContact = res.data.msg;
          this.showContactDialog = true;
          loading.close();
        } else {
          this.$message.error("Get Contact Fail!" + res.data.msg);
          loading.close();
        }
      });
    },
    updateContacts() {
      if (this.contact) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const id = localStorage.getItem("id");
        updateContact(id, this.contact).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("Update Successful!");
            loading.close();
            window.location.reload();
          } else {
            this.$message.error("Update Contact Fail!" + res.data.msg);
            loading.close();
          }
        });
      } else {
        this.$message.error("Please Input Contact!");
      }
    },
    getContacts() {
      const id = localStorage.getItem("id");
      getContact(id).then((res) => {
        if (res.data.code === 200) {
          if (res.data.msg === null) {
            this.$message.warning("Please Update Contact!");
            this.setContactDialog = true;
          } else {
            this.contact = res.data.msg;
          }
        } else {
          this.$message.error("Get Contact Fail!" + res.data.msg);
        }
      });
    },
    USDTPays() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      USDTPay(this.payamount).then((res) => {
        if (res.data.code === 200) {
          window.open(res.data.msg.url);
          loading.close();
        } else {
          this.$message.error("Get Pay Url Fail！" + res.data.msg);
          loading.close();
        }
      });
    },
    openPayDialog() {
      this.payDialog = true;
      this.getPayAmounts();
      this.getPayChannels();
    },
    getPayAmounts() {
      const id = localStorage.getItem("id");
      getPayAmount(id).then((res) => {
        if (res.data.code === 200) {
          this.payamount = res.data.msg;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    refreshContent() {
      this.getUserInfos();
    },
    screeneeWidth() {
      if (window.innerWidth < 768) {
        this.dialogWidth = "80%";
        this.isCollapse = true;
      } else {
        this.dialogWidth = "30%";
      }
    },
    open(code) {
      let existingTab = this.editableTabs.find(
        (tab) => tab.name === this.componentsList[code]
      );
      if (!existingTab) {
        this.currentView = this.componentsList[code];
        let menuName = {
          1: "User Manage",
          2: "Report Manage",
          3: "CreateVue",
          4: "Score Record",
        };
        this.editableTabs.push({
          title: menuName[code],
          name: this.componentsList[code],
          component: this.componentsList[code],
        });
        this.editableTabsValue = this.componentsList[code];
      } else {
        this.editableTabsValue = existingTab.name;
      }
    },
    handleTabsEdit(targetName, action) {
      if (action === "add") {
        let newTabName = ++this.editableTabs.length;
        this.editableTabs.push({
          title: "New Tab", // 这里可以根据需要设置新的标签页标题
          name: newTabName,
          component: "NewTabComponent", // 你需要替换为实际的组件名称
        });
        this.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      }
    },
    logOuts() {
      localStorage.setItem("isLogin", false);
      this.$message.success("LogOut Success!Refrush Page");
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    },
    getUserInfos() {
      const id = localStorage.getItem("id");
      getUserInfo(id).then((res) => {
        if (res.data.code === 200) {
          this.backpoint = res.data.msg.backpoint;
          this.payPoint = res.data.msg.paypoint;
          this.money = res.data.msg.money;
          this.share = res.data.msg.proportion;
          this.discount = res.data.msg.discount;
        } else {
          this.$message.error("Get User Info Failed!");
        }
      });
    },
  },
  created() {
    this.open(1);
    this.user = localStorage.getItem("username");
    this.money = localStorage.getItem("money");
    this.share = localStorage.getItem("proportion");
    this.payPoint = localStorage.getItem("paypoint");
    this.screeneeWidth();
    this.getUserInfos();
    this.getContacts();
  },
  components: {
    UserVue,
    QueryVue,
    CreateVue,
    RecordVue,
  },
};
</script>

<style>
body {
  margin: 0;
}
.custom-scroll {
  height: 100vh;
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #3498db;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.phone-menu {
  background-color: white;
  padding: 10px;
  font-size: 40px;
  border-radius: 50px;
  margin: 10px;
  position: fixed;
  bottom: 100px;
  left: 0;
  box-shadow: 0px 0px 10px 10px rgba(65, 106, 123, 0.1);
  z-index: 999;
  display: none;
}

.phone-card {
  display: none;
  background-color: #409eff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  width: 90vw;
  color: white;
  text-align: center;
  font-size: 20px;
  position: relative;
  z-index: 1;
}

.pc-data {
  margin: 0;
  transform: translateY(10px);
  font-style: italic;
  font-weight: bolder;
  margin-right: 20px;
}

.phone_btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .pc-data {
    display: none;
  }

  .phone-card {
    display: block;
  }

  .tabs {
    margin-top: 150px;
    background-color: white;
  }

  .phone_btn {
    display: block;
  }

  .phone-menu {
    display: block;
  }
}
</style>
